<template>
  <div>
    <b-alert v-model="isDraftAvailable" v-height-fade.appear variant="warning">
      <div class="alert-body">
        <b-row>
          <b-col class="align-self-center">
            Do you want to restore the draft data?
          </b-col>
          <b-col class="align-self-center text-right">
            <b-button @click="removeDraft" variant="outline-danger" class="mr-2"
              >Ignore</b-button
            >
            <b-button @click="restoreDraft" variant="primary">Restore</b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>

    <b-tabs>
      <b-tab active title="English">
        <b-card title="Cancellation Policy Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Description">
          <b-form
            ref="descriptionEngForm"
            class="repeater-form"
            @submit.prevent="repeatDescription(null)"
          >
           <quill-editor v-model="page.description" :options="snowOption" />
          </b-form>
        </b-card>
      </b-tab>
      <b-tab title="Hindi">
        <b-card title="Cancellation Policy Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translation.hi.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Description">
          <b-form
            ref="descriptionEngForm"
            class="repeater-form"
            @submit.prevent="repeatDescription(null)"
          >
           <quill-editor v-model="page.translation.hi.description" :options="snowOption" />
          </b-form>
        </b-card>
      </b-tab>
      <b-tab title="Marathi">
        <b-card title="Cancellation Policy Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translation.mr.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Description">
          <b-form
            ref="descriptionEngForm"
            class="repeater-form"
            @submit.prevent="repeatDescription(null)"
          >
           <quill-editor v-model="page.translation.mr.description" :options="snowOption" />
          </b-form>
        </b-card>
      </b-tab>
    </b-tabs>
    <b-card>
      <b-row>
        <b-col cols="6">
          <b-button size="lg" @click="saveDraft" block variant="outline-info"
            >Create Draft</b-button
          >
        </b-col>
        <b-col cols="6">
          <b-button size="lg" @click="savePage" block variant="primary"
            >Save Page</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BTabs,
  BTab,
  BButton,
  BFormTextarea,
  BImg,
  BAlert,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import { heightFade } from "@core/directives/animations";
import { getUserToken } from "@/auth/utils";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    quillEditor,
    BTabs,
    BTab,
    BButton,
    BFormTextarea,
    ComponentMediaSelector,
    BImg,
    BAlert,
  },
  data() {
    return {
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            ["link"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      isDraftAvailable: false,
      page: {
        pageTitle: "",
        description: "",
        translation: {
          hi: {
          pageTitle: "",
          description: ""
        },
        mr: {
          pageTitle: "",
          description: ""
        }
        }
      }
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    saveDraft() {
      localStorage.setItem("cancellation-policy-data", JSON.stringify(this.page));
      this.makeToast("success", "Success", `Draft Saved!`);
    },
    restoreDraft() {
      const page = localStorage.getItem("cancellation-policy-data");
      this.page = JSON.parse(page);
    },
    removeDraft() {
      localStorage.removeItem("cancellation-policy-data");
      this.isDraftAvailable = false;
    },
    getPage() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/pages/cancellationPolicy/all`)
        .then((response) => {
          this.page = response.data.cancellationPolicyData
          const { translation } = response.data.cancellationPolicyData
          this.page.translation = {
            hi: {
              pageTitle: translation && translation.hi.pageTitle ? translation.hi.pageTitle : "",
              description: translation && translation.hi.description ? translation.hi.description : "",
            },
            mr: {
              pageTitle: translation && translation.mr.pageTitle ? translation.mr.pageTitle : "",
              description: translation && translation.mr.description ? translation.mr.description : "",
            }
          }
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    savePage() {
      console.log(this.page);
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/pages/cancellationPolicy/save`, this.page, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
              this.makeToast(
                "success",
                "Success",
                `${this.page.pageTitle} has been added successfully!`
              );
            })
            .catch((error) => {
              this.makeToast(
                "danger",
                "Error",
                "Error while adding the tender!"
              );
              console.log(error.response);
            });
    },
  },
  mounted() {
    if (localStorage.getItem("cancellation-policy-data")) {
      this.isDraftAvailable = true;
    }
    this.getPage();
  },
};
</script>

<style>
</style>